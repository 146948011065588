import React from "react"
import { graphql } from "gatsby"

import Byline from "./byline"
import EventTitle from "./eventTitle"
import Layout from "./layout"
import Link from "./link"
import Snippet from "./snippet"
import SpeakerCard from "./speakerCard"

import alternatingStyle from "./alternatingList.module.css"

const EventList = ({ meta, lists, children }) => {
	return (
		<Layout meta={meta}>
			{children}
			{lists.map(({snippetId, events, showByline}) =>
				events.length > 0 && (
					<React.Fragment key={snippetId}>
						<Snippet id={snippetId} />
						{generateEvents(events, showByline)}
					</React.Fragment>
				)
			)}
		</Layout>
	)
}

function generateEvents(events, showByline) {
	if (!events || events.length === 0) return <span />
	return (
		<React.Fragment>
			{events
				.filter(event => !event.frontmatter.canceled)
				.map(event => generateEvent(event, showByline))}
		</React.Fragment>
	)
}

function generateEvent(event, showByline) {
	const containerStyle = event.frontmatter.speakers.length < 3 ? alternatingStyle.container : alternatingStyle.fullContainer
	return (
		<div className={containerStyle} key={event.fields.path}>
			<div className={alternatingStyle.speakers}>{images(event.frontmatter.speakers)}</div>
			<div className={alternatingStyle.event}>
				<h3 className={alternatingStyle.title}>
					<EventTitle event={event} linkTitle />
				</h3>
				{showByline && <Byline track={event.frontmatter.track} format={event.fields.collection} slug={event.frontmatter.slug} />}
				<p className={alternatingStyle.teaser}>
					{event.frontmatter.excerpt} <Link to={event.fields.path}>More...</Link>
					{event.frontmatter.video && <> / <Link to={event.frontmatter.video}>Video</Link></>}
					{event.frontmatter.slides && (
						<>
							{event.frontmatter.slides.url && <> / <Link to={event.frontmatter.slides.url}>Slides</Link></>}
							{event.frontmatter.slides.pdf && <> / <a href={`/slides/${event.frontmatter.slides.pdf}`}>Slides (PDF)</a></>}
						</>
					)}
				</p>
			</div>
		</div>
	)
}

function images(speakers) {
	if (!speakers || speakers.length === 0) return

	return speakers.map(speaker => (
		<SpeakerCard
			speakerSlug={speaker.frontmatter.slug}
			size="small"
			linkImage
			showName
			linkName
			className={alternatingStyle.image}
			key={speaker.frontmatter.slug}
		/>
	))
}

// AFTER: add `slides { pdf url }` to `frontmatter`
export const query = graphql`
	fragment AllEventNodes on MarkdownRemarkConnection {
		nodes {
			fields {
				collection
				path
			}
			frontmatter {
				title
				excerpt
				track
				slug
				language
				canceled
				speakers {
					fields {
						path
					}
					frontmatter {
						slug
					}
				}
			}
		}
	}
`

export default EventList
