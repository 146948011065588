import React from "react"
import { graphql } from "gatsby"

import EventList from "../components/eventList"

export default ({ data }) => (
	<EventList
		type="Panels"
		meta={{
			title: "Panels",
			description:
				"TODO",
			path: "/panels",
		}}
		lists={[
			{
				snippetId: "panels-intro",
				events: data.panels.nodes,
				showByline: true,
			},
		]}
	/>
)

export const query = graphql`
	query {
		panels: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { fields: { collection: { eq: "panels" } } }
		) {
			...AllEventNodes
		}
	}
`
