import React from "react"

import style from "./byline.module.css"

import { findBlock, trackType, trackText, eventFormatText, timeSlotText } from "../infra/functions"

const Byline = ({type, track, format, slug}) => {
	if (!type) type = trackType(track)
	const block = findBlock(slug)
	return (
		<div className={style[`${type}Byline`]}>
			{track && <span className={style.track}>{trackText(track)}</span>}
			{format && <span className={style.format}>{eventFormatText(format)}</span>}
			{block && <span className={style.time}>{timeSlotText(block.start, block.end)}</span>}
		</div>
	)
}

export default Byline
